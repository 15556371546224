import * as React from "react"

import Bio from "../components/jumbotron"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Index = ({ location }) => {
  return (
    <Layout location={location} title={""}>
      <Seo title="Hey Dumbass" description={`Being trans in ${new Date().getFullYear()} doesn't mean having a mental disorder. Wanting to vote Republican does.`} />
      <Bio />
    </Layout>
  )
}

export default Index
