/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"

const Jumbotron = () => {

  const date = new Date().getFullYear()
  return (
    <>
      <h3 className="">Being trans in {date} doesn't mean having a mental disorder.</h3>
      <h3><span>Wanting</span> to vote Republican in <span>{date}</span> does.</h3>
      <h1 style={{'wordBreak': 'break-all'}}>#DontVoteRepublican</h1>
    </>
  )
}

export default Jumbotron
